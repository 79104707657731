<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>
      <Input
          v-model="model.cardNumber"
          :label="$t('components.cards.addCard.labelCardNumber')"
          :state="!validation.hasError('model.cardNumber')"
          :error-text="validation.firstError('model.cardNumber')"
          :requred="true"
      ></Input>
      <Input
          v-model="model.pin"
          :label="$t('components.cards.addCard.labelPin')"
          :state="!validation.hasError('model.pin')"
          :error-text="validation.firstError('model.pin')"
          :requred="true"
      ></Input>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">{{$t('actions.cancel')}}</Button>
        <Button visual-style="fill" :loading="isLoading">{{$t('components.cards.addCard.btnCardAdd')}}</Button>
      </div>
    </div>
  </form>
</template>

<script>
import SimpleVueValidator from 'simple-vue-validator';
import ApiService from "@/services/api.service";

const Validator = SimpleVueValidator.Validator;

export default {
  props:{
  },
  components: {
  },
  data() {
    return {
      model:{
        cardNumber:'',
        pin:''
      },
      errorText:'',
      submitted:false,
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
        if (success) {

          this.isLoading=true;

          ApiService.addCard(this.model).then((resp)=>{

            this.$emit('success',resp.data);
            this.isLoading=false;
          }).catch((err)=>{
            let data = err.data;
            this.errorText = data.errorText;
            this.isLoading=false;
          });

        }
      })
    }
  },
  created() {
    this.model.value=this.value;
  },
  validators: {
    "model.cardNumber": function (value) {
      if (this.submitted)
          return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.pin": function (value) {
      if (this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    }
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>
