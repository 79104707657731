<template>
    <div class="container container_small" v-show="!loading">
      <div class="profile">
        <div class="profile__header">
          <div class="profile__title">{{$t('cards.title')}}</div>
          <nav class="profile__nav">
            <router-link to="/"  class="btn btn__secondary btn__secondary_outline-green">{{$t('menu.main')}}</router-link>
            <router-link to="/profile" class="btn btn__secondary btn__secondary_outline-green">{{$t('menu.profile')}}</router-link>
            <router-link to="/cards" class="btn btn__secondary btn__secondary_outline-green">{{$t('menu.cards')}}</router-link>
          </nav>
        </div>
        <div class="profile__cards">
          <div class="profile__cards-list">
            <div class="profile__cards-list-item" @click="openModalAddCard=true">
              <div class="profile__cards-list-item-icon">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0H9V15H6V0Z" fill="#6D6D6D"/>
                  <path d="M15 6V9L0 9L1.31135e-07 6L15 6Z" fill="#6D6D6D"/>
                </svg>
              </div>
              <div class="profile__cards-list-item-text">{{$t('actions.add')}}</div>
            </div>

            <router-link
                v-for="(card,index) in cards"
                :to="{name:'CardInfo', params:{number:card.Number}}"
                :key="index"
                class="profile__cards-list-item"
                style="background: #E9F7D6;"
                tag="a"
            >
              <span></span>
              <img v-if="card.CardImageUrl" :src="card.CardImageUrl">
            </router-link>
          </div>

          <template v-if="currentCard">
          <div class="profile__cards-user">
            <div class="profile__cards-user-name">{{ currentCard.FullName }} <span>{{ currentCard.Number }}</span></div>
            <div class="profile__cards-user-split">|</div>
            <div class="profile__cards-user-state">{{priceFormat(currentCard.MoneyBalance)}} {{$t('cards.textStateDate')}} {{ currentCard.DateInfo }}</div>
          </div>
          <div class="profile__cards-info">
            <div class="profile__sidebar">
              <router-link :to="{name:'CardInfo', params:{number:currentCard.Number}}" class="profile__sidebar-item">{{$t('cards.menu.info')}}</router-link>
              <router-link :to="{name:'CardTopUp', params:{number:currentCard.Number}}" class="profile__sidebar-item">{{$t('cards.menu.topUp')}}</router-link>
              <router-link :to="{name:'CardTransfer', params:{number:currentCard.Number}}" class="profile__sidebar-item">{{$t('cards.menu.transfer')}}</router-link>
              <router-link :to="{name:'CardHistory', params:{number:currentCard.Number}}" class="profile__sidebar-item">{{$t('cards.menu.history')}}</router-link>
            </div>
            <router-view></router-view>
          </div>
          </template>
        </div>
      </div>
      <Dialog
          buttonsType="save"
          v-if="openModalAddCard"
          @close="openModalAddCard = false"
      >
        <div slot="header">
          <h3>{{$t('cards.modalCardAdd')}}</h3>
        </div>
        <div slot="body">
          <AddCard
              @cancel="openModalAddCard=false"
              @success="()=>{openModalAddCard=false;getCards();}"
          ></AddCard>
        </div>
      </Dialog>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";

import Dialog from '@/components/Controlls/Dialog';

import AddCard from "@/components/cards/AddCard";

export default {
  components: {
    Dialog,
    AddCard
  },
  data() {
    return {
      openModalAddCard: false,
      cardNumber:null
    }
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      cards:"cards/cards"
    }),
    currentCard(){
      for(let card of this.cards){
        if(card.Number == this.cardNumber)
          return card;
      }

      return null;
    }
  },
  methods:{
    ...mapActions({
      'getCards':'cards/getCards'
    }),
    ...mapMutations({
      setStatusLoading: "setStatusLoading"
    }),
    redirect(){
      if(!this.cardNumber){
        if(this.cards[0]){
          this.$router.push({
            name:'CardInfo',
            params:{
              number:this.cards[0].Number
            }
          })
        }
      }
    }
  },
  created() {

    this.cardNumber = this.$router.currentRoute.params.number;

    this.setStatusLoading(true);
    this.getCards().then(()=>{
      this.redirect();
      this.setStatusLoading(false);
    });
  },
  watch:{
    $route(to) {
      this.cardNumber=to.params.number;
      this.redirect();
    }
  }
}
</script>
